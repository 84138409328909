import React from 'react';
import styled from '@emotion/styled';
import { BorderedButton } from '@/components/button/button';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Link } from '@/components/link/link';

const MainDiv = styled(Grid)`
  margin: 0;
  height: 870px;
  position: relative;
  padding-left: 12.5%;
  background: white;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
    height: auto;
    margin-bottom: 0;
  }
  & a {
    :hover {
      text-decoration: none;
    }
  }
`;
const Content = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 760px) {
    padding: 40px 20px 0 20px;
    justify-content: flex-start;
  }
`;
const HostBlob = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 760px) {
    justify-content: flex-start;
  }
`;
const MainBlob = styled.img`
  position: absolute;
  width: 61%;
  height: auto;
  min-height: 1150px;
  max-height: 1250px;
  right: 0;
  z-index: 1;
  margin: -42vh 0 0 0;
`;
const MobileBlob = styled.img`
  left: 0;
  right: auto;
  height: auto;
  width: 100%;
  z-index: 1;
`;
const HostImage = styled.img`
  width: 39.2%;
  height: auto;
  z-index: 1;
  position: absolute;
  @media (max-width: 760px) {
    right: -50px;
    margin-top: 20px;
    width: 353px;
    height: 353px;
    position: absolute;
    z-index: 2;
  }
`;

const TextDiv = styled.div`
  width: 300px;
  @media (max-width: 760px) {
    width: 100%;
  }
`;
const Heading = styled(Typography)`
  font-family: var(--font-cooper-light), sans-serif;
  margin-bottom: 32px;
  font-size: 40px;
  line-height: 112.3%;
  align-items: center;
  color: #231727;
  @media (max-width: 760px) {
    width: 70%;
    margin-bottom: 20px;
    font-size: 32px;
  }
`;
const Text = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 159.3%;
  min-width: 343px;
  text-align: left;
  color: #231727;
  @media (max-width: 768px) {
    font-size: 16px;
    min-width: unset;
    font-style: normal;
    font-weight: normal;
  }
`;

const CommunityHosts = () => {
  const isMobile = useMediaQuery('(max-width:960px)');
  return (
    <MainDiv container>
      {!isMobile && <MainBlob src="/community/blob.png" alt={'bg-blob'} />}
      <Content item md={6} sm={12} xs={12}>
        <TextDiv>
          <Heading>Hosts anchor our community</Heading>
          <Text>
            A Host is anyone who shares their curiosities and interests with others. Hosts come from
            all walks of life. Everyone has something to offer!
          </Text>
          <Link href={'/hosts'}>
            <BorderedButton primary={false} label="Meet our Hosts" />
          </Link>
        </TextDiv>
      </Content>
      <HostBlob item md={6} sm={12} xs={12}>
        {isMobile ? (
          <MobileBlob src="/community/mobile-blob.png" alt={'blob'} />
        ) : (
          <HostImage src="/community/people.png" alt={'host'} />
        )}
      </HostBlob>
    </MainDiv>
  );
};

export default CommunityHosts;
